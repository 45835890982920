<template>
  <div class="login">
    <img class="loginBg" src="../image/login-bg.jpg" />
    <div class="input">
      <p class="input-name">手机号</p>
      <div class="input-border">
        <input
          placeholder="手机号"
          type="text"
          maxlength="11"
          v-model="mobile"
        />
      </div>
    </div>

    <router-link tag="div" class="forget" to="/forget">忘记密码？</router-link>

    <div class="input">
      <p class="input-name">密码</p>
      <div class="input-border">
        <input placeholder="密码" type="password" v-model="password" />
      </div>
    </div>

    <div class="button">
      <div class="btn" @click="login">登陆</div>
       <div class="btn" @click="register">注册</div>
    </div>

    <Tips :tip="msg" />
    <div class="beian">明九章 版权所有 <a href="https://beian.miit.gov.cn/">浙ICP备2020042292号-1</a></div>
  </div>
</template>

<script>
  export default {
    beforeMount() {
      document.title = '明九章-登陆';
    },
    mounted(){
      let token = localStorage.getItem('ng-token');
      if (token) {
        this.$router.replace('/');
      }
    },
    data () {
      return {
        mobile: '',
        password: '',
        msg: ''
      }
    },
    methods: {
      register() {
        this.$router.push('/register')
      },
      login() {
        if (this.mobile == '') {
            alert('手机号不能为空');
            return;
        }
        if (this.password == '') {
            alert('密码不能为空');
            return;
        }
        this.$http.put('/api/passwordLogin', {
            mobile: this.mobile,
            password: this.password
        })
        .then((res) => {
            localStorage.setItem('ng-data', JSON.stringify(res.data.data));
            localStorage.setItem('ng-token', res.data.data.token);
            this.msg = '登录成功';
            setTimeout(() => {
              this.$router.push('/main/home');
            })
        }).catch((err) => {
            this.validErrorInfo = err.errorMsg;
        })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .login {
    .loginBg {
      width: 100%;
      height: auto;
      margin-bottom: 0.2rem;
    }
    .input {
      padding: 0 0.3rem;
      color: #79cdca;
      font-size: 0.3rem;
      .input-name {
        margin-bottom: 0.12rem;
        font-size: 0.26rem;
      }
      .input-border {
        border: 1px solid #79cdca;
        height: 0.9rem;
        border-radius: 0.12rem;
        display: flex;
        align-items: center;
        input {
          color: #333333;
          height: 0.75rem;
          width: 92%;
          margin: 0 auto;
          display: block;
          font-size: 0.3rem;
        }
      }
    }
    .button {
      padding: 0 0.3rem 1rem;
      box-sizing: border-box;
      margin: 0.5rem auto 0;
      border-radius: 0.12rem;
      color: white;
      overflow: hidden;
      margin-bottom: 1rem;
      .btn {
        background-color: #79cdca;
        width: 100%;
        height: 0.9rem;
        border-radius: 0.12rem;
        text-align: center;
        line-height: 0.9rem;
        font-size: 0.32rem;
        cursor: pointer;
        margin-top: 0.2rem;
      }
    }
    .forget {
      text-align: right;
      padding: 0 0.3rem;
      box-sizing: border-box;
      font-size: 0.25rem;
      margin-top: 0.1rem;
      color: #79cdca;
      cursor: pointer;
    }
  }
  .beian {
    margin-top: 2rem;
    padding-bottom: 0.5rem;
    text-align: center;
    color: #ccc;
    font-size: 0.3rem;
    a {
      &:visited{ 
        text-decoration: none; 
      }
      text-decoration: none;
      color: #ccc;
    }
  }
</style>
